import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PatentsContents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="ai-with-patents">
        <div className="inner">
          <div className="page-title">
            <h2 className="title" data-aos="fade-up">
              {t("patents.top.header")}
            </h2>
            <p data-aos="fade-up">
              {t("patents.top.content.1")} <br />
              {t("patents.top.content.2")}
            </p>
            <div className="img"></div>
          </div>

          <div className="txt-top">
            <div className="inner">
              <h3 data-aos="fade-up">{t("patents.txt.top.header")}</h3>
              <dl data-aos="fade-up">
                <dt>{t("patents.txt.top.content.1.header")}</dt>
                <dd>{t("patents.txt.top.content.1.content")}</dd>
              </dl>
              <dl data-aos="fade-up">
                <dt>{t("patents.txt.top.content.2.header")}</dt>
                <dd>{t("patents.txt.top.content.2.content")}</dd>
              </dl>
              <dl data-aos="fade-up">
                <dt>{t("patents.txt.top.content.3.header")}</dt>
                <dd>{t("patents.txt.top.content.3.content")}</dd>
              </dl>
            </div>
          </div>

          <div className="txt-bottom">
            <h3>{t("patents.txt.bottom.header")}</h3>
            <div className="boxs">
              <dl data-aos="flip-left">
                <dt>{t("patents.txt.bottom.content.1.header")}</dt>
                <dd>{t("patents.txt.bottom.content.1.content")}</dd>
              </dl>
              <dl data-aos="flip-left">
                <dt>{t("patents.txt.bottom.content.2.header")}</dt>
                <dd>{t("patents.txt.bottom.content.2.content")}</dd>
              </dl>
              <dl data-aos="flip-left">
                <dt>{t("patents.txt.bottom.content.3.header")}</dt>
                <dd>{t("patents.txt.bottom.content.3.content")}</dd>
              </dl>
            </div>
            <p>{t("patents.txt.bottom.subcontent")}</p>
          </div>

          <div className="patents-spot">
            <div className="inner">
              <h3>
                {t("patents.txt.spot.header.1")} <br />
                {t("patents.txt.spot.header.2")}
              </h3>
              <div className="list">
                <dl data-aos="fade-up" className="patent1">
                  <dt>
                    <span className="no">
                      {t("patents.txt.spot.patentNo")} 10-1583400
                    </span>
                    <span className="title">
                      {t("patents.txt.spot.patent1.header")}
                    </span>
                  </dt>
                  <dd>{t("patents.txt.spot.patent1.content")}</dd>
                </dl>
                <dl data-aos="fade-up" className="patent2">
                  <dt>
                    <span className="no">
                      {t("patents.txt.spot.patentNo")} 10-2245942
                    </span>
                    <span className="title">
                      {t("patents.txt.spot.patent2.header")}
                    </span>
                  </dt>
                  <dd>{t("patents.txt.spot.patent2.content")}</dd>
                </dl>
                <dl data-aos="fade-up" className="patent3">
                  <dt>
                    <span className="no">
                      {t("patents.txt.spot.patentNo")} 10-2333325
                    </span>
                    <span className="title">
                      {t("patents.txt.spot.patent3.header")}
                    </span>
                  </dt>
                  <dd>{t("patents.txt.spot.patent3.content")}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="patents-list">
            <div className="patents patents1" data-aos="fade-up">
              <div className="title">
                <p className="no">
                  {t("patents.txt.spot.patentNo")} 10-1583400
                </p>
                <h3>{t("patents.txt.spot.list.1.header")}</h3>
              </div>
              <div className="txt">
                <p>{t("patents.txt.spot.list.1.txt1")}</p>
              </div>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt1")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd1")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt2")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd2")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt3")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd3")}</dd>
              </dl>
              <div className="bg"></div>
              <div className="txt line">
                <p>{t("patents.txt.spot.list.1.txt2")}</p>
              </div>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt4")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd4")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt5")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd5")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.1.dt6")}</dt>
                <dd>{t("patents.txt.spot.list.1.dd6")}</dd>
              </dl>
              <div className="txt line">
                <p>{t("patents.txt.spot.list.1.txt3")}</p>
              </div>
            </div>

            <div className="patents patents2" data-aos="fade-up">
              <div className="title">
                <p className="no">
                  {t("patents.txt.spot.patentNo")} 10-2245942
                </p>
                <h3>{t("patents.txt.spot.list.2.header")}</h3>
              </div>
              <div className="txt">
                <p>{t("patents.txt.spot.list.2.txt1")}</p>
              </div>

              <dl>
                <dt>{t("patents.txt.spot.list.2.dt1")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd1")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.2.dt2")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd2")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.2.dt3")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd3")}</dd>
              </dl>
              <div className="bg"></div>
              <div className="txt line line-m-none">
                <p>{t("patents.txt.spot.list.2.txt2")}</p>
              </div>
              <dl>
                <dt>{t("patents.txt.spot.list.2.dt4")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd4")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.2.dt5")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd5")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.2.dt6")}</dt>
                <dd>{t("patents.txt.spot.list.2.dd6")}</dd>
              </dl>
              <div className="txt line">
                <p>{t("patents.txt.spot.list.2.txt3")}</p>
              </div>
            </div>

            <div className="patents patents3" data-aos="fade-up">
              <div className="title">
                <p className="no">
                  {t("patents.txt.spot.patentNo")} 10-2333325
                </p>
                <h3>{t("patents.txt.spot.list.3.header")}</h3>
              </div>
              <div className="txt">
                <p>{t("patents.txt.spot.list.3.txt1")}</p>
              </div>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt1")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd1")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt2")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd2")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt3")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd3")}</dd>
              </dl>
              <div className="bg"></div>
              <div className="txt line line-m-none">
                <p>{t("patents.txt.spot.list.3.txt2")}</p>
              </div>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt4")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd4")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt5")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd5")}</dd>
              </dl>
              <dl>
                <dt>{t("patents.txt.spot.list.3.dt6")}</dt>
                <dd>{t("patents.txt.spot.list.3.dd6")}</dd>
              </dl>
              <div className="txt line">
                <p>{t("patents.txt.spot.list.3.txt3")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatentsContents;
