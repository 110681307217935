import React, { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import i18n from "../../../locales/i18n";
import useIsMobile from "../../../utils/isMobile";
import { useNavigate } from "react-router-dom";

const show = {
  display: "block",
  opacity: 1,
  transition: `opacity 0.2s`,
};

const hide = {
  display: "none",
  opacity: 0,
  transition: `opacity 0.2s`,
};

//TODO: 서브메뉴들 FadeIn FadeOut 효과 추가해야함
const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLangMenu, setShowLangMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  const languageRef = useRef(null);

  const [currentLang, setCurrentLang] = useState("en");

  const isMobile = useIsMobile();

  // 메뉴 외부 클릭 시 메뉴 닫기 함수
  const handleClose = useCallback(
    (e) => {
      if (
        showLangMenu &&
        languageRef.current !== null &&
        !languageRef.current.contains(e.target)
      ) {
        setShowLangMenu(false);
      }
    },
    [showLangMenu]
  );

  const toggleLangMenu = () => {
    setShowLangMenu((prev) => !prev);
  };

  const changeLang = (lang) => {
    setCurrentLang(lang);
    i18n?.changeLanguage(lang);
    if (!isMobile) {
      toggleLangMenu();
    }
    // navigate(router.pathname, router.asPath, { locale: lang });
  };

  const toggleMobileMenu = () => {
    setShowMenu((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile) {
      setShowLangMenu(false);
      setShowMenu(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (showMenu) {
      const body = document.querySelector("body");
      if (body) {
        body.classList.add("no_scroll");
      }
    } else {
      const body = document.querySelector("body");
      if (body) {
        body.classList.remove("no_scroll");
      }
    }
  }, [showMenu]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("here");
    document.addEventListener("click", handleClose);

    return () => document.removeEventListener("click", handleClose);
  }, [handleClose]);

  return (
    <header
      className={
        fixed || location.pathname !== "/"
          ? `header fixed ${showMenu && "layer-open"}`
          : `header ${showMenu && "layer-open"}`
      }
      style={{ backgroundColor: fixed ? "#fff" : "" }}
    >
      <div className="inner">
        <h1>
          <a
            onClick={() => navigate("/")}
            className="logo"
            style={{ cursor: "pointer" }}
          >
            <span>JCG</span>
          </a>
        </h1>
        <button className="btn-menu-open" onClick={toggleMobileMenu}>
          <span>menu open</span>
        </button>
        <div className="menu">
          <nav className="gnb">
            <ul>
              <li>
                <a
                  onClick={() => {
                    navigate("/weare");
                    if (isMobile) {
                      toggleMobileMenu();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("header.weare")}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/blockchain");
                    if (isMobile) {
                      toggleMobileMenu();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("header.blockchain")}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/patents");
                    if (isMobile) {
                      toggleMobileMenu();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("header.patents")}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/service");
                    if (isMobile) {
                      toggleMobileMenu();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("header.services")}
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/contact");
                    if (isMobile) {
                      toggleMobileMenu();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("header.contact")}
                </a>
              </li>
            </ul>
          </nav>
          <div className="util" ref={languageRef}>
            <span className="language">
              <a
                href="javascript:;"
                className="btn-language"
                onClick={toggleLangMenu}
              >
                언어 변경
              </a>
              <div
                className="layer layer-language"
                style={
                  isMobile
                    ? {
                        opacity: 1,
                      }
                    : showLangMenu
                    ? show
                    : hide
                }
              >
                <ul>
                  <li className="en">
                    <a
                      href="javascript:;"
                      className={currentLang === "en" ? "active" : ""}
                      onClick={() => {
                        changeLang("en");
                        if (isMobile) {
                          toggleMobileMenu();
                        }
                      }}
                    >
                      EN
                    </a>
                  </li>
                  <li className="ko">
                    <a
                      href="javascript:;"
                      className={currentLang === "ko" ? "active" : ""}
                      onClick={() => {
                        changeLang("ko");
                        if (isMobile) {
                          toggleMobileMenu();
                        }
                      }}
                    >
                      KO
                    </a>
                  </li>
                  <li className="es">
                    <a
                      href="javascript:;"
                      className={currentLang === "es" ? "active" : ""}
                      onClick={() => {
                        changeLang("es");
                        if (isMobile) {
                          toggleMobileMenu();
                        }
                      }}
                    >
                      ES
                    </a>
                  </li>
                  <li className="pt">
                    <a
                      href="javascript:;"
                      className={currentLang === "pt" ? "active" : ""}
                      onClick={() => {
                        changeLang("pt");
                        if (isMobile) {
                          toggleMobileMenu();
                        }
                      }}
                    >
                      PT
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </div>
    </header>
    // <header
    //   className={`header ${showMenu && "layer-open"}`}
    //   style={{ backgroundColor: fixed ? "#121419" : "" }}
    // >
    //   <div className="inner">
    //     <h1>
    //       <a
    //         className="logo"
    //         onClick={() => navigate("/")}
    //         style={{ cursor: "pointer" }}
    //       >
    //         <span>8BIT CENTER</span>
    //       </a>
    //     </h1>
    //     <button className="btn-menu-open" onClick={toggleMobileMenu}>
    //       <span>menu open</span>
    //     </button>
    //     <div className="menu">
    //       <nav className="gnb">
    //         <ul>
    //           <li>
    //             <a
    //               href="#"
    //               onClick={() => {
    //                 navigate("/introduce");
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //               }}
    //             >
    //               INTRODUCE
    //             </a>
    //           </li>
    //           <li className={`game ${showGameMenu && "open"}`}>
    //             <a
    //               className="depth1"
    //               onClick={toggleGameMenu}
    //               style={{ cursor: "pointer" }}
    //             >
    //               GAMES
    //             </a>
    //             <div
    //               className="layer-2depth"
    //               style={showGameMenu || isMobile ? show : hide}
    //             >
    //               <ul>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       toggleGameMenu();
    //                       navigate("/game/oddandeven");
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                     }}
    //                     style={{
    //                       cursor: "pointer",
    //                     }}
    //                   >
    //                     ODD OR EVEN
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       toggleGameMenu();
    //                       navigate("/game/highlow");
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                     }}
    //                     style={{
    //                       cursor: "pointer",
    //                     }}
    //                   >
    //                     HIGH-LOW
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       toggleGameMenu();
    //                       navigate("/game/freegame");
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                     }}
    //                     style={{
    //                       cursor: "pointer",
    //                     }}
    //                   >
    //                     FREE GAME
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //           <li>
    //             <a
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //                 navigate("/dashboard");
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               DASHBOARD
    //             </a>
    //           </li>
    //           <li className={`supprot ${showSuppportMenu && "open"}`}>
    //             <a
    //               className="depth1"
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleSupportMenu();
    //                 } else {
    //                   navigate("/support/notice");
    //                   if (isMobile) {
    //                     toggleMobileMenu();
    //                   }
    //                 }
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               SUPPORT
    //             </a>
    //             <div
    //               className="layer-2depth"
    //               style={showSuppportMenu ? show : hide}
    //             >
    //               <ul>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       toggleSupportMenu();
    //                       navigate("/support/notice");
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                     }}
    //                     style={{ cursor: "pointer" }}
    //                   >
    //                     NOTICE
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       toggleSupportMenu();
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                       navigate("/support/faq");
    //                     }}
    //                     style={{ cursor: "pointer" }}
    //                   >
    //                     FAQ
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     onClick={() => {
    //                       if (isMobile) {
    //                         toggleMobileMenu();
    //                       }
    //                       toggleSupportMenu();
    //                       navigate("/support/contact");
    //                     }}
    //                     style={{ cursor: "pointer" }}
    //                   >
    //                     CONTACT
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //         </ul>
    //       </nav>
    //       <div className="util">
    //         <div className="wallet-info" onClick={toggleConnectWalletModal}>
    //           {auth.address ? (
    //             <div className="wallet-info-inner">
    //               <div
    //                 className="profile"
    //                 onMouseEnter={showLogoutOnEnter}
    //                 onMouseLeave={hideLogoutOnLeave}
    //               >
    //                 {auth.email ? (
    //                   <img
    //                     src="/img/common/profile_on.png"
    //                     alt=""
    //                     onClick={toggleAdditionalInfoModal}
    //                     style={{
    //                       position: "relative",
    //                       zIndex: 10,
    //                     }}
    //                   />
    //                 ) : (
    //                   <>
    //                     <img
    //                       src="/img/common/profile_off.png"
    //                       alt=""
    //                       onClick={toggleAdditionalInfoModal}
    //                       style={{
    //                         position: "relative",
    //                         zIndex: 10,
    //                       }}
    //                     />
    //                     <span
    //                       className={`layer-q ${
    //                         alreadyFadedQuestionMark
    //                           ? Styles.hide
    //                           : fadeOutQuestionMark
    //                           ? Styles.fadeout
    //                           : ""
    //                       }`}
    //                     >
    //                       ?
    //                     </span>
    //                   </>
    //                 )}

    //                 <div
    //                   className="layer-logout"
    //                   style={
    //                     isMobile
    //                       ? {
    //                           opacity: 1,
    //                         }
    //                       : showLogout
    //                       ? show
    //                       : hide
    //                   }
    //                   onClick={logout}
    //                 >
    //                   <a href="#">LOG OUT</a>
    //                 </div>
    //               </div>
    //               <div className="user">
    //                 <img src="/img/common/ico_user_metamask.png" alt="" />
    //                 <span className="id">{auth.address}</span>
    //                 <span
    //                   className={`layer-success ${
    //                     alreadyFaded
    //                       ? Styles.hide
    //                       : fadeOut
    //                       ? Styles.fadeout
    //                       : Styles.hide
    //                   }`}
    //                 >
    //                   Connection Successful
    //                 </span>
    //               </div>
    //             </div>
    //           ) : (
    //             <a href="#" className="btn">
    //               CONNECT WALLET
    //             </a>
    //           )}
    //           {/* <a href="#" className="btn">
    //             CONNECT WALLET
    //           </a> */}

    //           {/* <div className="wallet-info-inner">
    //             <div className="profile">
    //               <img src="./img/common/profile_off.png" alt="" />
    //               <span className="layer-q">?</span>
    //             </div>
    //             <div className="user">
    //               <img src="./img/common/ico_user_metamask.png" alt="" />
    //               <span className="id">0x6f3c0x6f3c0x6f3c</span>
    //               <span className="layer-success">Connection Successful</span>
    //             </div>
    //           </div> */}
    //         </div>
    //         <span className="language">
    //           <a href="#" className="btn-language" onClick={toggleLangMenu}>
    //             언어 변경
    //           </a>
    //           <div
    //             className="layer layer-language"
    //             style={
    //               isMobile
    //                 ? {
    //                     opacity: 1,
    //                   }
    //                 : showLangMenu
    //                 ? show
    //                 : hide
    //             }
    //           >
    //             <ul>
    //               <li className="en" onClick={() => changeLang("en")}>
    //                 <a
    //                   href="#"
    //                   className={`${currentLang === "en" && "active"}`}
    //                 >
    //                   EN
    //                 </a>
    //               </li>
    //               <li className={`kr`} onClick={() => changeLang("kr")}>
    //                 <a
    //                   href="#"
    //                   className={`${currentLang === "kr" && "active"}`}
    //                 >
    //                   KR
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </span>

    //         {auth.address && (
    //           <div className="btn-logout" onClick={logout}>
    //             <a href="#">LOG OUT</a>
    //           </div>
    //         )}
    //       </div>

    //       <div className="link-footer">
    //         <h2>8bit.center</h2>
    //         <ul>
    //           <li>
    //             <a
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //                 navigate("/info/about");
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               About us
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //                 navigate("/info/termsofuse");
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               Terms of use
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //                 navigate("/info/privacy");
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               Privacy policy
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               onClick={() => {
    //                 if (isMobile) {
    //                   toggleMobileMenu();
    //                 }
    //                 navigate("/info/cookie");
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               Cookie policy
    //             </a>
    //           </li>
    //         </ul>
    //         <div className="sns">
    //           <a href="" className="telegram">
    //             telegram
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
  );
};

// Hydartion error가 떠서 Lazy Loading으로 해결함.
// dynamic
export default Navbar;
