import { BrowserRouter, Routes, Route } from "react-router-dom";
import BlockchainContents from "./components/Blockchain/BlockchainContents";
import ContactContents from "./components/Contact/ContactContents";
import Footer from "./components/layouts/Footer/Footer";
import MainLayout from "./components/layouts/Main/MainLayout";
import Navbar from "./components/layouts/Navbar/Navbar";
import Main from "./components/Main/Main";
import PatentsContents from "./components/Patents/PatentsContents";
import ServicesAndExpertiseContents from "./components/ServicesAndExpertise/ServicesAndExpertiseContents";
import WeAreContents from "./components/WeAre/WeAreContents";
import "./default.css";
import "./main.css";
import "./store.css";
import "./sub.css";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <Navbar />
              <Main />
              <Footer />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/weare"
          element={
            <MainLayout>
              <Navbar />
              <WeAreContents />
              <Footer />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/patents"
          element={
            <MainLayout>
              <Navbar />
              <PatentsContents />
              <Footer />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/blockchain"
          element={
            <MainLayout>
              <Navbar />
              <BlockchainContents />
              <Footer />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/service"
          element={
            <MainLayout>
              <Navbar />
              <ServicesAndExpertiseContents />
              <Footer />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/contact"
          element={
            <MainLayout>
              <Navbar />
              <ContactContents />
              <Footer />
            </MainLayout>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
