import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const WeAreContents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="we-are">
        <div className="inner">
          <div className="page-title">
            <h2 className="title" data-aos="fade-up">
              {t("weare.top.header")}
            </h2>
            <p data-aos="fade-up">
              {t("weare.top.content.1")}
              <br />
              {t("weare.top.content.2")}
            </p>
          </div>

          <div className="img"></div>

          <div className="txt txt1" data-aos="fade-up">
            <h3>{t("weare.txt1.header")}</h3>
            <p>{t("weare.txt1.content1")}</p>
            <p>{t("weare.txt1.content2")}</p>
            <div className="bg"></div>
            <p>{t("weare.txt1.content3")}</p>
            <p>{t("weare.txt1.content4")}</p>
            <p>{t("weare.txt1.content5")}</p>
          </div>

          <div className="txt txt2" data-aos="fade-up">
            <h3>{t("weare.txt2.header")}</h3>
            <p>{t("weare.txt2.content1")}</p>
            <p>{t("weare.txt2.content2")}</p>
            <p>{t("weare.txt2.content3")}</p>
            <p>{t("weare.txt2.content4")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeAreContents;
