import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="top">
        <div className="inner">
          <h1 data-aos="fade-up">
            {t("main.top.header.1")}
            <br />
            {t("main.top.header.2")}
          </h1>
        </div>
      </div>

      <div className="ai-patents">
        <div className="inner">
          <h2>
            {t("main.patents.header.1")}
            <br />
            {t("main.patents.header.2")}
          </h2>
          <div className="item item1">
            <div className="bg"></div>
            <h3 data-aos="fade-up">{t("main.patents.item1.header")}</h3>
            <p data-aos="fade-up">{t("main.patents.item1.content")}</p>
          </div>
          <div className="item item2">
            <div className="bg"></div>
            <h3 data-aos="fade-up">{t("main.patents.item2.header")}</h3>
            <p data-aos="fade-up">{t("main.patents.item2.content")}</p>
          </div>
          <div className="item item3">
            <div className="bg"></div>
            <h3 data-aos="fade-up">{t("main.patents.item3.header")}</h3>
            <p data-aos="fade-up">{t("main.patents.item3.content")}</p>
          </div>
          <div className="item item4">
            <div className="bg"></div>
            <h3 data-aos="fade-up">{t("main.patents.item4.header")}</h3>
            <p data-aos="fade-up">{t("main.patents.item4.content")}</p>
          </div>
        </div>
      </div>

      <div className="history">
        <div className="inner">
          <h2>{t("main.history.title")}</h2>
          <p>{t("main.history.description")}</p>

          <div className="list">
            <dl data-aos="fade-up">
              <dt>2023</dt>
              <dd>{t("main.history.2023.1")}</dd>
              <dd>{t("main.history.2023.2")}</dd>
              <dd>{t("main.history.2023.3")}</dd>
              <dd>{t("main.history.2023.4")}</dd>
            </dl>

            <dl data-aos="fade-up">
              <dt>2022</dt>
              <dd>{t("main.history.2022.1")}</dd>
              <dd>{t("main.history.2022.2")}</dd>
              <dd>{t("main.history.2022.3")}</dd>
            </dl>

            <dl data-aos="fade-up">
              <dt>2021</dt>
              <dd>{t("main.history.2021.1")}</dd>
              <dd>{t("main.history.2021.2")}</dd>
              <dd>{t("main.history.2021.3")}</dd>
            </dl>

            <dl data-aos="fade-up">
              <dt>2020</dt>
              <dd>{t("main.history.2020.1")}</dd>
              <dd>{t("main.history.2020.2")}</dd>
              <dd>{t("main.history.2020.3")}</dd>
              <dd>{t("main.history.2020.4")}</dd>
            </dl>

            <dl data-aos="fade-up" className="last">
              <dt>2019</dt>
              <dd>{t("main.history.2019.1")}</dd>
              <dd>{t("main.history.2019.2")}</dd>
              <dd>{t("main.history.2019.3")}</dd>
            </dl>

            <div className="bg"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
