import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ServicesAndExpertiseContents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contents">
      <div className="services-and-expertise">
        <div className="inner">
          <div className="page-title">
            <h2 className="title" data-aos="fade-up">
              {t("services.header")}
            </h2>
            <p data-aos="fade-up">
              {t("services.content.1")} <br />
              {t("services.content.2")}
            </p>
          </div>

          <div className="img"></div>

          <div className="txt-list">
            <div className="item item1" data-aos="fade-up">
              <dl>
                <dt>{t("services.item1.dt")}</dt>
                <dd>{t("services.item1.dd.1")}</dd>
                <dd>{t("services.item1.dd.2")}</dd>
                <dd>{t("services.item1.dd.3")}</dd>
                <dd>{t("services.item1.dd.4")}</dd>
              </dl>
            </div>

            <div className="item item2" data-aos="fade-up">
              <dl>
                <dt>{t("services.item2.dt")}</dt>
                <dd>{t("services.item2.dd.1")}</dd>
                <dd>{t("services.item2.dd.2")}</dd>
                <dd>{t("services.item2.dd.3")}</dd>
                <dd>{t("services.item2.dd.4")}</dd>
              </dl>
            </div>

            <div className="item item3" data-aos="fade-up">
              <dl>
                <dt>{t("services.item3.dt")}</dt>
                <dd>{t("services.item3.dd.1")}</dd>
                <dd>{t("services.item3.dd.2")}</dd>
                <dd>{t("services.item3.dd.3")}</dd>
                <dd>{t("services.item3.dd.4")}</dd>
                <dd>{t("services.item3.dd.5")}</dd>
              </dl>
            </div>

            <div className="bg"></div>

            <div className="item item4" data-aos="fade-up">
              <dl>
                <dt>{t("services.item4.dt")}</dt>
                <dd>{t("services.item4.dd.1")}</dd>
                <dd>{t("services.item4.dd.2")}</dd>
                <dd>{t("services.item4.dd.3")}</dd>
                <dd>{t("services.item4.dd.4")}</dd>
                <dd>{t("services.item4.dd.5")}</dd>
              </dl>
            </div>

            <div className="item item5" data-aos="fade-up">
              <dl>
                <dt>{t("services.item5.dt")}</dt>
                <dd>{t("services.item5.dd.1")}</dd>
                <dd>{t("services.item5.dd.2")}</dd>
                <dd>{t("services.item5.dd.3")}</dd>
                <dd>{t("services.item5.dd.4")}</dd>
              </dl>
            </div>

            <div className="txt" data-aos="fade-up">
              <p>{t("services.txt1")}</p>
              <p>{t("services.txt2")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesAndExpertiseContents;
